/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/owncloud01.jpeg",
    alt: "Cliente de escritorio de Owncloud",
    title: "ownCloud Client"
  }), "El desarrollo de ownCloud está ultimamente muy activo, ya hablé hace tiempo de ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2012/02/23/owncloud-3-y-mas/"
  }, "la inclusión de Akregator en ownCloud"), ", este proyecto ya ", React.createElement(_components.a, {
    href: "http://algorithmsforthekitchen.com/blog/?p=225"
  }, "está en pleno desarrollo"), ", se trata de una aplicación llamada News, nombre que me parece muy acertado por cierto."), "\n", React.createElement(_components.p, null, "Pero no solo ésto, ya se ha liberado la aplicación de escritorio de ownCloud, con ella tendremos un funcionamiento muy parecido al de Dropbox, sin tener que utilizar carpetas mediante el protocolo ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/WebDAV",
    title: "WebDAV"
  }, "WebDAV"), " que aunque como estándar web está muy bien, se hace realmente incomodo trabajar sobre ella puesto que tienes que esperar a que se suba cada archivo."), "\n", React.createElement(_components.p, null, "Yo por mi parte me he creado una cuenta en ", React.createElement(_components.a, {
    href: "https://owncube.com/",
    title: "OwnCube"
  }, "OwnCube"), ", un servidor gratuito de ownCloud que aunque va un poco lento y supongo que es cosa del servidor y no de ownCloud en sí , tampoco me molesta."), "\n", React.createElement(_components.p, null, "Para información sobre como poner todo esto en marcha podéis mirar ", React.createElement(_components.a, {
    href: "http://blog.unlugarenelmundo.es/2012/04/29/probando-owncloud-el-dropbox-libre/"
  }, "este"), " blog donde está muy bien explicado."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
